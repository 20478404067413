import React from 'react';

const SvgGraphic = () => {
    return (
        <svg className="main-svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 474.81 549.52">
            >
            <defs>
                <style>
                    {`
          .cls-1 {
            fill: url(#linear-gradient-9);
            opacity: 0.3;
          }

          .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12 {
            stroke-width: 0px;
          }

          .cls-2 {
            fill: url(#linear-gradient);
          }

          .cls-3 {
            fill: url(#linear-gradient-6);
          }

          .cls-13 {
            isolation: isolate;
          }

          .cls-4 {
            fill: #ffde17;
          }

          .cls-5 {
            fill: #231f20;
            mix-blend-mode: multiply;
            opacity: 0.1;
          }

          .cls-6 {
            fill: url(#linear-gradient-5);
          }

          .cls-7 {
            fill: url(#linear-gradient-2);
          }

          .cls-8 {
            fill: #4c5a60;
          }

          .cls-9 {
            fill: url(#linear-gradient-7);
          }

          .cls-10 {
            fill: url(#linear-gradient-3);
          }

          .cls-11 {
            fill: url(#linear-gradient-8);
          }

          .cls-12 {
            fill: url(#linear-gradient-4);
          }
        `}
                </style>
                <linearGradient
                    id="linear-gradient"
                    x1="-3288.26"
                    y1="-1124.2"
                    x2="-3036.13"
                    y2="-1124.2"
                    gradientTransform="translate(-886.7 3437.2) rotate(90)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#a6a8ac" />
                    <stop offset="0.05" stopColor="#adafb3" />
                    <stop offset="0.27" stopColor="#c6c7ca" />
                    <stop offset="0.49" stopColor="#d8d9da" />
                    <stop offset="0.73" stopColor="#e2e3e4" />
                    <stop offset="1" stopColor="#e6e7e8" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-2"
                    x1="-3281.66"
                    y1="-1124.2"
                    x2="-3042.73"
                    y2="-1124.2"
                    gradientTransform="translate(-886.7 3437.2) rotate(90)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#fff" />
                    <stop offset="0.24" stopColor="#fbfbfb" />
                    <stop offset="0.44" stopColor="#f1f1f1" />
                    <stop offset="0.63" stopColor="#dfe0e1" />
                    <stop offset="0.82" stopColor="#c6c7ca" />
                    <stop offset="1" stopColor="#a6a8ac" />
                    <stop offset="1" stopColor="#a6a8ac" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-3"
                    x1="237.29"
                    y1="0"
                    x2="237.29"
                    y2="151.05"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#f0efef" />
                    <stop offset="1" stopColor="#a6a8ac" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-4"
                    x1="476.63"
                    y1="58.41"
                    x2="476.63"
                    y2="209.46"
                    gradientTransform="translate(284.14 -304.28) rotate(59.6)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#cfd0d0" />
                    <stop offset="1" stopColor="#d2d2d3" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-5"
                    x1="666.44"
                    y1="-264.9"
                    x2="666.44"
                    y2="-113.85"
                    gradientTransform="translate(564.96 -304.95) rotate(120.32) scale(1 -1)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#cfd0d0" />
                    <stop offset="1" stopColor="#d0d1d2" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-6"
                    x1="269.27"
                    y1="-333.96"
                    x2="269.27"
                    y2="-182.91"
                    gradientTransform="translate(506.78 215.56) rotate(-180)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#f0efef" />
                    <stop offset="1" stopColor="#e7e8e9" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-7"
                    x1="204.77"
                    y1="-138.16"
                    x2="204.77"
                    y2="12.89"
                    gradientTransform="translate(222.64 519.84) rotate(-120.4)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#cfd0d0" />
                    <stop offset="1" stopColor="#e4e5e6" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-8"
                    x1="362.02"
                    y1="-405.89"
                    x2="362.02"
                    y2="-254.84"
                    gradientTransform="translate(-58.18 520.51) rotate(-59.68) scale(1 -1)"
                    xlinkHref="#linear-gradient-7"
                />
                <linearGradient
                    id="linear-gradient-9"
                    x1="231.47"
                    y1="286.53"
                    x2="231.47"
                    y2="161.04"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#fff" />
                    <stop offset="0.03" stopColor="rgba(255, 255, 255, 0.95)" stopOpacity="0.95" />
                    <stop offset="0.14" stopColor="rgba(255, 255, 255, 0.73)" stopOpacity="0.73" />
                    <stop offset="0.25" stopColor="rgba(255, 255, 255, 0.53)" stopOpacity="0.53" />
                    <stop offset="0.37" stopColor="rgba(255, 255, 255, 0.37)" stopOpacity="0.37" />
                    <stop offset="0.49" stopColor="rgba(255, 255, 255, 0.24)" stopOpacity="0.24" />
                    <stop offset="0.61" stopColor="rgba(255, 255, 255, 0.13)" stopOpacity="0.13" />
                    <stop offset="0.74" stopColor="rgba(255, 255, 255, 0.06)" stopOpacity="0.06" />
                    <stop offset="0.86" stopColor="rgba(255, 255, 255, 0.01)" stopOpacity="0.01" />
                    <stop offset="1" stopColor="#fff" stopOpacity="0" />
                </linearGradient>
            </defs>
            <g className="cls-13">
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <g>
                            <polygon
                                className="cls-2"
                                points="128.33 211.97 237.5 148.94 346.68 211.97 346.68 338.04 237.5 401.07 128.33 338.04 128.33 211.97"
                            />
                            <polygon
                                className="cls-7"
                                points="134.04 334.74 134.04 215.27 237.5 155.54 340.96 215.27 340.96 334.74 237.5 394.47 134.04 334.74"
                            />
                            <polygon
                                className="cls-8"
                                points="139.75 331.44 139.75 218.57 237.5 162.14 335.25 218.57 335.25 331.44 237.5 387.88 139.75 331.44"
                            />
                            <polygon
                                className="cls-5"
                                points="328.8 214.94 328.84 328.2 236.99 380.44 139.75 325.84 139.75 331.44 237.5 387.88 335.25 331.44 335.25 218.57 328.8 214.94"
                            />
                            <polygon
                                className="cls-10"
                                points="237.02 0 240.61 150.74 237.5 148.94 233.98 151.05 237.02 0"
                            />
                            <polygon
                                className="cls-12"
                                points="474.81 136.15 346.61 215.52 346.58 211.93 342.99 209.97 474.81 136.15"
                            />
                            <polygon
                                className="cls-6"
                                points="0 136.37 128.31 215.56 128.33 211.97 131.92 210 0 136.37"
                            />
                            <polygon
                                className="cls-3"
                                points="237.79 549.52 234.2 398.78 237.3 400.58 240.82 398.47 237.79 549.52"
                            />
                            <polygon
                                className="cls-9"
                                points="0 413.37 128.2 334 128.22 337.58 131.82 339.55 0 413.37"
                            />
                            <polygon
                                className="cls-11"
                                points="474.81 413.15 346.5 333.96 346.48 337.54 342.88 339.52 474.81 413.15"
                            />
                            <g>
                                <path
                                    className="cls-4"
                                    d="M237.85,332.84c-1.58-2.68-3.08-5.2-4.57-7.73-.79-1.34-1.45-2.78-2.4-3.99-1.01-1.29-2.8-1.12-4.2-1.72-10.72-4.59-17.18-12.57-19.31-24.03-.31-1.69-.37-3.41-.37-5.13,0-11.41-.05-22.82.01-34.24.07-11.55,5.01-20.5,14.69-26.79,2.79-1.81,5.84-3.08,9.11-3.76.83-.17,1.07-.43.88-1.33-.56-2.64-1.19-5.24-2.4-7.66-1.12-2.25-2.64-4.13-4.79-5.48-.39-.25-.63-.21-1.01.03-.97.62-1.99.51-2.77-.2-.76-.69-.97-1.73-.54-2.66.4-.86,1.37-1.42,2.3-1.31,1.06.12,1.79.82,2.02,1.93.07.34.05.66.44.89,3.84,2.26,5.79,5.88,7.07,9.97.48,1.52.76,3.08,1.08,4.63.1.5.23.72.79.63,2.35-.36,4.72-.34,7.08-.12.63.06.61-.31.68-.68.38-1.9.79-3.8,1.45-5.63,1.24-3.46,3.04-6.51,6.24-8.52.46-.29.73-.6.78-1.2.08-1,.93-1.76,1.92-1.9.92-.13,1.92.4,2.33,1.25.43.89.28,1.93-.38,2.62-.71.74-1.78.98-2.66.44-.57-.35-.91-.28-1.44.07-2.76,1.82-4.3,4.49-5.43,7.48-.65,1.73-1.02,3.54-1.43,5.34-.19.81.02,1.02.79,1.19,11.56,2.58,19.31,9.52,23.34,20.61,1.11,3.07,1.55,6.28,1.55,9.54,0,11.77.03,23.54,0,35.31-.04,13.94-9.16,25.81-22.76,29.72-.65.19-1.04.51-1.37,1.08-2.19,3.75-4.4,7.47-6.71,11.38ZM237.84,295.86c-7.91,0-15.82,0-23.72,0q-1.37,0-1.02,1.3c3.17,11.9,14.35,19.88,26.61,19,10.89-.78,20.26-8.59,22.93-19.11q.3-1.18-.88-1.18c-7.97,0-15.95,0-23.92,0ZM237.91,250.21h0c7.98,0,15.95,0,23.93,0q1.12,0,.83-1.12c-1.49-5.57-4.48-10.15-8.99-13.74-14.42-11.49-36.15-4.11-40.66,13.79-.22.89-.05,1.09.87,1.08,8.01-.03,16.02-.02,24.02-.02ZM237.86,267.85c8.17,0,16.33-.02,24.5.02.96,0,1.21-.28,1.16-1.2-.08-1.65-.07-3.31,0-4.96.03-.84-.18-1.09-1.06-1.08-10.5.03-21,.02-31.5.02-5.93,0-11.86.01-17.79-.02-.78,0-.99.21-.96.98.06,1.65.01,3.3.01,4.96q0,1.28,1.24,1.28c8.13,0,16.27,0,24.4,0ZM237.75,285.42c8.24,0,16.47-.01,24.71.02.88,0,1.09-.24,1.06-1.08-.07-1.62-.02-3.24-.02-4.86q0-1.26-1.25-1.26c-14.72,0-29.44,0-44.16,0-1.59,0-3.18.05-4.77-.02-.93-.04-1.14.3-1.11,1.16.06,1.68.07,3.37,0,5.06-.03.84.26,1.01,1.04,1,8.17-.03,16.34-.02,24.51-.02Z"
                                />
                                <path
                                    className="cls-4"
                                    d="M263.54,273.91c0-6.26,0-12.52,0-18.77,0-.4-.22-.9.58-.92,6.27-.2,12.37.58,18.08,3.31,12.29,5.86,19.53,15.52,21.65,28.99.57,3.62.67,7.25-.46,10.78-2.38,7.45-7.37,12.32-15,14.04-7.66,1.73-14.32-.46-19.65-6.25-3.5-3.8-5.17-8.4-5.19-13.56-.03-5.87,0-11.74,0-17.61ZM297.55,290.72c-.09-2.67-.47-5.69-1.51-8.58-4.21-11.76-12.46-18.75-24.79-20.93-.81-.14-1.06-.06-1.06.83.03,9.76-.03,19.52.03,29.28.05,7.69,5.87,13.7,13.27,13.87,7.86.18,14.08-6.05,14.05-14.47Z"
                                />
                                <path
                                    className="cls-4"
                                    d="M212.19,274.07c0,5.77.01,11.54,0,17.31-.03,12.11-9.62,21.2-21.72,20.58-9.44-.48-17.78-8.21-18.75-17.62-.85-8.23,1.23-15.84,5.67-22.75,5.71-8.89,13.85-14.35,24.17-16.55,3.32-.71,6.69-.74,10.07-.71.63,0,.56.36.56.75,0,3.27,0,6.55,0,9.82,0,3.05,0,6.1,0,9.14ZM205.52,277.05c0-4.96,0-9.92,0-14.88,0-.42.25-1.03-.66-.89-9.1,1.39-16.35,5.79-21.49,13.44-3.76,5.59-5.64,11.8-5.02,18.57.71,7.82,8.54,13.42,16.22,11.76,6.54-1.41,10.91-6.82,10.96-13.6.03-4.8,0-9.6,0-14.4Z"
                                />
                                <path
                                    className="cls-4"
                                    d="M221.51,240.12c.05-2.09,1.81-3.78,3.91-3.76,2.13.02,3.88,1.84,3.83,3.96-.05,2.14-1.87,3.87-3.98,3.81-2.16-.07-3.8-1.82-3.76-4.01Z"
                                />
                                <path
                                    className="cls-4"
                                    d="M254.19,240.22c.01,2.16-1.71,3.91-3.86,3.91-2.13,0-3.9-1.78-3.89-3.9.01-2.09,1.75-3.84,3.84-3.87,2.14-.03,3.9,1.71,3.91,3.86Z"
                                />
                            </g>
                            <path
                                className="cls-1"
                                d="M323.18,211.6s-73.63,77.59-183.42,63.4v-56.43l97.75-56.44,85.68,49.47Z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default SvgGraphic;
